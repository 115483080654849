import { render } from 'preact/compat';
import Widget from './components/Widget';
import Highlight from './lib/Highlight';
import { RoughAnnotation } from 'rough-notation/lib/model';
import { selectStrategies } from './lib/util';
import { HEY_GENIUS_FIELD, HOST_CLASSNAME } from './constants';

function loadPublicSettings() {
  // from window variables or from div attribute (for Chrome extension)
  const settingsElement = document.querySelector('[data-hg-settings]');
  if (settingsElement) {
    const settingsAttribute = settingsElement.getAttribute('data-hg-settings');
    if (settingsAttribute) {
      try {
        const settings = JSON.parse(settingsAttribute);
        window[HEY_GENIUS_FIELD] = settings;
      } catch (e) {
        console.error('Error parsing widget settings from HTML attribute:', e);
      }
    }
  }
}
if (!document.querySelector(`.${HOST_CLASSNAME}`)) {
  const host = document.createElement('div');
  host.classList.add(HOST_CLASSNAME);
  host.style.position = 'fixed';
  host.style.zIndex = '9999';
  host.style.pointerEvents = 'none';
  document.body.append(host);
  const shadow = host.attachShadow({ mode: 'open' });
  window['HeyGenius'] = window['HeyGenius'] ?? {};
  loadPublicSettings();
  const { dataRetrievalStrategy, processingStrategy } = selectStrategies();
  window['HeyGenius'].version = import.meta.env['VITE_APP_VERSION'];
  render(
    <Widget<{
      highlights?: Highlight[];
      annotations?: { [key: string]: RoughAnnotation[] };
    }>
      // projectId={window?.[HEY_GENIUS_FIELD]?.app_id}
      dataRetrievalStrategy={dataRetrievalStrategy}
      processingStrategy={processingStrategy}
    />,
    shadow,
  );
}
