import { annotate } from 'rough-notation';
import { RoughAnnotation } from 'rough-notation/lib/model';
import { colors } from './highlights';
import { ProcessingStrategy } from './ProcessingStrategy';
import { isInViewport, getCoords } from './util';
import { CLASSNAME_PREFIX, HOST_CLASSNAME } from 'src/constants';

export class LegacyProcessingStrategy
  implements
    ProcessingStrategy<{
      annotations: { [key: string]: RoughAnnotation[] };
    }>
{
  public process(highlights) {
    const annotations = this.createAnnotations(highlights);

    const cuepointReachedHandler = ({ highlightElementId }) => {
      const highlight = highlights.find(
        (highlight) => highlight.id === highlightElementId,
      );
      let element;

      if (highlight.selector) {
        element = document.querySelector(highlight.selector);
        if (highlight.index) {
          element = document.querySelectorAll(highlight.selector)[
            highlight.index
          ];
        }
      }
      if (!element) return;
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      const container = document.querySelector(
        '.' + HOST_CLASSNAME,
      ) as HTMLElement;
      const draggable = container.shadowRoot!.querySelector(
        '.react-draggable',
      ) as HTMLElement;
      container.style.bottom = '10px';
      container.style.left = '10px';
      container.style.transition = 'all 0.5s ease-in-out';
      draggable.style.transition = 'transform 0.5s ease-in-out';
      setTimeout(() => {
        draggable.style.transition = 'none';
      }, 500);
      let scrollTimeout;

      // We need to wait until the scroll is finished before starting the animation
      const showAnnotations = function (): void {
        annotations[highlightElementId].forEach((annotation) =>
          annotation.show(),
        );
        window.removeEventListener('scroll', showAfterScroll);
      };
      function showAfterScroll() {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(showAnnotations, 100);
      }

      if (isInViewport(element)) {
        annotations[highlightElementId].forEach((annotation) =>
          annotation.show(),
        );
        window.removeEventListener('scroll', showAfterScroll);
      }
      addEventListener('scroll', showAfterScroll);
    };
    // @ts-expect-error Legacy code
    window.cuepointReachedHandler = cuepointReachedHandler;
    document.addEventListener(
      'cuepointReached',
      cuepointReachedHandler as unknown as EventListener,
      false,
    );
    return { annotations };
  }

  private createAnnotations(highlights) {
    const annotations: Record<string, RoughAnnotation[]> = {};

    highlights.forEach((highlight) => {
      let element: HTMLElement[] | HTMLElement | null = null;
      if (highlight.absolute) {
        // get coords and append to body,
        // with the height being the difference of start and end elements of absolute
        const startElement = document.querySelector(highlight.absolute.start);
        const endElement = document.querySelector(highlight.absolute.end);
        const startCoords = getCoords(startElement);
        const endCoords = getCoords(endElement);
        const height = endCoords.top - startCoords.top;
        const width = startElement.getBoundingClientRect().width;
        // console.log(startCoords, endCoords, height, width);
        // console.log(endElement.getBoundingClientRect().width);
        const wrapper = document.createElement('div');
        wrapper.style.position = 'absolute';
        wrapper.style.top = startCoords.top + 'px';
        wrapper.style.left = startCoords.left + 'px';
        wrapper.style.height = height + 'px';
        wrapper.style.width = width + 'px';
        document.body.appendChild(wrapper);
        wrapper.classList.add(`${CLASSNAME_PREFIX}hl-` + highlight.id);
        highlight.selector = '.' + `${CLASSNAME_PREFIX}hl-` + highlight.id;
      }
      if (highlight.wrap) {
        const startElement = document.querySelector(highlight.wrap.start);

        const parent = startElement.parentNode;
        const elements = Array.from(parent.children).slice(
          highlight.wrap.index,
          highlight.wrap.index + highlight.wrap.count + 1,
        ) as HTMLElement[];
        const wrapper = document.createElement('div');
        const wrapSelector = `${CLASSNAME_PREFIX}hl-` + highlight.id;
        wrapper.classList.add(wrapSelector);
        // clone elements and add to wrapper, remove from the parent and reattach wrapped at the index of wrap
        elements.forEach((element: HTMLElement) => {
          wrapper.appendChild(element.cloneNode(true));
          parent.removeChild(element);
        });
        parent.insertBefore(wrapper, parent.childNodes[highlight.wrap.index]);
        highlight.selector = '.' + wrapSelector;
      }
      if (highlight.selector) {
        element = document.querySelector(highlight.selector) as HTMLElement;
        element = [element];
        if (highlight.textOnly) {
          const elem = element[0];
          const span = document.createElement('span');
          span.innerHTML = elem.innerHTML;
          elem.innerHTML = '';
          elem.appendChild(span);
          span.classList.add(`${CLASSNAME_PREFIX}hl-` + highlight.id);
          highlight.selector = '.' + `${CLASSNAME_PREFIX}hl-` + highlight.id;
          element = Array.from(document.querySelectorAll(highlight.selector));
        }
      }
      const page = window.location.pathname.split('/').pop();
      const COLOR_PRIMARY = colors[page].primary;
      const COLOR_SECONDARY = colors[page].secondary;
      if (element) {
        Array.from(element).forEach((element) => {
          if (highlight.annotationOptions.color === 'primary') {
            highlight.annotationOptions.color = COLOR_PRIMARY;
          } else if (highlight.annotationOptions.color === 'secondary') {
            highlight.annotationOptions.color = COLOR_SECONDARY;
          }
          highlight.annotationOptions.strokeWidth =
            highlight.annotationOptions.strokeWidth || '10';
          highlight.annotationOptions.padding =
            highlight.annotationOptions.padding || '2';

          const annotation = annotate(element, highlight.annotationOptions);
          // annotation.show();
          this.pushAnnotation(annotations, annotation, highlight.id);
        });
      }
    });
    return annotations;
  }

  private pushAnnotation(annotations, annotation, id) {
    if (!annotations[id]) {
      annotations[id] = [annotation];
    } else {
      annotations[id].push(annotation);
    }
  }
}
